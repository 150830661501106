import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import user from './user'
import shipNature from './ship/shipNature'
import shipConsignee from './ship/shipConsignee'
import shipOperation from './ship/shipOperation'
import shipMouvement from './ship/shipMouvement'
import cargo from './nature-cargo'
import quotation from './type-quotation'
import tender from './tender'

// Actualites
import news from './news'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    news,
    shipNature,
    shipConsignee,
    shipOperation,
    shipMouvement,
    cargo,
    quotation,
    tender,
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
