/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    shipConsigneeProcess: null,
    shipConsigneeSuccess: null,
    shipConsigneeError: null,

    shipListConsigneeProcess: null,
    shipListConsigneeSuccess: null,
    shipListConsigneeError: null,

    updateShipConsigneeProcess: null,
    updateShipConsigneeSuccess: null,
    updateShipConsigneeError: null,

    deleteShipConsigneeProcess: null,
    deleteShipConsigneeError: null,
    deleteShipConsigneeSuccess: null,
  },
  getters: {
    shipConsigneeProcess: state => state.shipConsigneeProcess,
    shipConsigneeSuccess: state => state.shipConsigneeSuccess,
    shipConsigneeError: state => state.shipConsigneeError,

    shipListConsigneeProcess: state => state.shipListConsigneeProcess,
    shipListConsigneeSuccess: state => state.shipListConsigneeSuccess,
    shipListConsigneeError: state => state.shipListConsigneeError,

    updateShipConsigneeProcess: state => state.updateShipConsigneeProcess,
    updateShipConsigneeSuccess: state => state.updateShipConsigneeSuccess,
    updateShipConsigneeError: state => state.updateShipConsigneeError,

    deleteShipConsigneeProcess: state => state.deleteShipConsigneeProcess,
    deleteShipConsigneeSuccess: state => state.deleteShipConsigneeSuccess,
    deleteShipConsigneeError: state => state.deleteShipConsigneeError,
  },
  mutations: {

    SHIP_CONSIGNEE_PROCESS(state, payload) {
      state.shipConsigneeProcess = payload
    },
    SHIP_CONSIGNEE_SUCCESS(state, payload) {
      state.shipConsigneeSuccess = payload
    },
    SHIP_CONSIGNEE_ERROR(state, payload) {
      state.shipConsigneeError = payload
    },

    CLEAR_SHIP_CONSIGNEE(state) {
      state.shipConsigneeError = null
      state.shipConsigneeProcess = null
      state.shipConsigneeSuccess = null
    },

    LISTSHIP_CONSIGNEE_PROCESS(state, payload) {
      state.shipListConsigneeProcess = payload
    },
    LISTSHIP_CONSIGNEE_SUCCESS(state, payload) {
      state.shipListConsigneeSuccess = payload
    },
    LISTSHIP_CONSIGNEE_ERROR(state, payload) {
      state.shipListConsigneeError = payload
    },

    CLEAR_LISTSHIP_CONSIGNEE(state) {
      state.shipListConsigneeError = null
      state.shipListConsigneeProcess = null
      state.shipListConsigneeSuccess = null
    },

    UPDATE_SHIP_CONSIGNEE_PROCESS(state, payload) {
      state.updateShipConsigneeProcess = payload
    },
    UPDATE_SHIP_CONSIGNEE_SUCCESS(state, payload) {
      state.updateShipConsigneeSuccess = payload
    },
    UPDATE_SHIP_CONSIGNEE_ERROR(state, payload) {
      state.updateShipConsigneeError = payload
    },
    CLEAR_UPDATE_SHIP_CONSIGNEE(state) {
      state.updateShipConsigneeError = null
      state.updateShipConsigneeProcess = null
      state.updateShipConsigneeSuccess = null
    },

    DELETE_SHIP_CONSIGNEE_PROCESS(state, payload) {
      state.deleteShipConsigneeProcess = payload
    },
    DELETE_SHIP_CONSIGNEE_SUCCESS(state, payload) {
      state.deleteShipConsigneeSuccess = payload
    },
    DELETE_SHIP_CONSIGNEE_ERROR(state, payload) {
      state.deleteShipConsigneeError = payload
    },
    CLEAR_DELETE_SHIP_CONSIGNEE(state) {
      state.deleteShipConsigneeSuccess = null
      state.deleteShipConsigneeProcess = null
      state.deleteShipConsigneeError = null
    },
  },
  actions: {
    shipConsignee({ commit, state }, payload) {
      commit('CLEAR_SHIP_CONSIGNEE')
      commit('SHIP_CONSIGNEE_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
        description: payload.description,
        address: payload.address,
      })
      axios
        .post('/api/ship-consignee/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('SHIP_CONSIGNEE_PROCESS', false)
          commit('SHIP_CONSIGNEE_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('SHIP_CONSIGNEE_PROCESS', false)
          if (error.response) {
            commit('SHIP_CONSIGNEE_ERROR', error.response.data)
          } else if (error.request) {
            commit('SHIP_CONSIGNEE_ERROR', error.request)
          } else {
            commit('SHIP_CONSIGNEE_ERROR', error.message)
          }
        })
    },
    shipListConsignee({ commit }, payload) {
      commit('CLEAR_LISTSHIP_CONSIGNEE')
      commit('LISTSHIP_CONSIGNEE_PROCESS', true)
      const data = JSON.stringify({
        data_list: payload.dataList,
      })
      axios
        .post('/api​/ship-consignee​/add-all', data)
        .then(res => {
          commit('LISTSHIP_CONSIGNEE_PROCESS', false)
          commit('LISTSHIP_CONSIGNEE_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('LISTSHIP_CONSIGNEE_PROCESS', false)
          if (error.response) {
            commit('LISTSHIP_CONSIGNEE_ERROR', error.response.data)
          } else if (error.request) {
            commit('LISTSHIP_CONSIGNEE_ERROR', error.request)
          } else {
            commit('LISTSHIP_CONSIGNEE_ERROR', error.message)
          }
        })
    },
    updateShipConsignee({ commit, state }, payload) {
      commit('CLEAR_UPDATE_SHIP_CONSIGNEE')
      commit('UPDATE_SHIP_CONSIGNEE_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
        description: payload.description,
        address: payload.address,
      })
      axios({
        method: 'put',
        url: `/api/ship-consignee/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_SHIP_CONSIGNEE_PROCESS', false)
          commit('UPDATE_SHIP_CONSIGNEE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_SHIP_CONSIGNEE_PROCESS', false)
          if (error.response) {
            commit('UPDATE_SHIP_CONSIGNEE_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_SHIP_CONSIGNEE_ERROR', error.request)
          } else {
            commit('UPDATE_SHIP_CONSIGNEE_ERROR', error.message)
          }
        })
    },
    deleteShipConsignee({ commit, state }, payload) {
      commit('CLEAR_DELETE_SHIP_CONSIGNEE')
      commit('DELETE_SHIP_CONSIGNEE_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/ship-consignee/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_SHIP_CONSIGNEE_PROCESS', false)
          commit('DELETE_SHIP_CONSIGNEE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_SHIP_CONSIGNEE_PROCESS', false)
          if (error.response) {
            commit('DELETE_SHIP_CONSIGNEE_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_SHIP_CONSIGNEE_ERROR', error.request)
          } else {
            commit('DELETE_SHIP_CONSIGNEE_ERROR', error.message)
          }
        })
    },
  },
}
