import { canAccess } from '@/auth/utils'

export default [
  {
    path: '/app/dashboard',
    name: 'app-home',
    component: () => import('@/views/app/admin/dashboard/index.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_USER'])) {
        next()
      } else {
        next('/app/dashboard')
      }
    },
    meta: {
      pageTitle: 'Tableau de bord',
      breadcrumb: [
        {
          text: 'Accueil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/profile',
    name: 'app-profile',
    component: () => import('@/views/app/admin/profile/AccountSetting.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_USER'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Mon Profil',
      breadcrumb: [
        {
          text: 'Informations générales',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/add-news',
    name: 'app-news',
    component: () => import('@/views/app/admin/actualites/add.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Actualités',
      breadcrumb: [
        {
          text: 'Ajouter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/news-list',
    name: 'app-news-list',
    component: () => import('@/views/app/admin/actualites/listing.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Actualités',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/add-tender',
    name: 'app-tenders',
    component: () => import('@/views/app/admin/appelOffre/add.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: "Appel d'Offre",
      breadcrumb: [
        {
          text: 'Ajouter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/tenders-list',
    name: 'app-tenders-list',
    component: () => import('@/views/app/admin/appelOffre/listing.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_DIR_COM'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: "Appel d'Offre",
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/quote/listing',
    name: 'app-quote',
    component: () => import('@/views/app/admin/demandeCotation/listing.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_OPERATION'])) {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Demande de cotation',
      breadcrumb: [
        {
          text: 'Liste des demandes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/ship-movement',
    name: 'app-ship-movement',
    redirect: '/app/ship-movement/add',
    component: () => import('@/views/app/admin/movement/index.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PREVISION_NAVIRE'])) {
        next()
      } else {
        next(to.name)
      }
    },
    children: [
      {
        path: '/app/ship-movement/add',
        name: 'app-ship-movement-add',
        component: () => import('@/views/app/admin/movement/add.vue'),
        meta: {
          pageTitle: 'Mouvement Navire',
          breadcrumb: [
            {
              text: 'Paramètres',
              to: '/app/settings',
            },
            // {
            //   text: 'Liste',
            //   to: '/admin/settings/nature-cargo/listing',
            // },
            {
              text: 'Nouveau',
              active: true,
            },
          ],
        },
      },
      {
        path: '/app/ship-movement/listing',
        name: 'app-ship-movement-listing',
        component: () => import('@/views/app/admin/movement/listing.vue'),
        meta: {
          pageTitle: 'Mouvement Navire',
          breadcrumb: [
            {
              text: 'Paramètres',
              to: '/app/settings',
            },
            {
              text: 'Liste',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/app/settings',
    name: 'app-settings',
    redirect: '/app/settings/home',
    component: () => import('@/views/app/admin/settings/index.vue'),
    beforeEnter: (to, _, next) => {
      if (canAccess(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])) {
        next()
      } else {
        next()
      }
    },
    children: [
      {
        path: '/app/settings/home',
        name: 'app-settings-home',
        component: () => import('@/views/app/admin/settings/HomeSettings.vue'),
        meta: {
          pageTitle: 'Système',
          breadcrumb: [
            {
              text: 'Accueil',
              active: true,
            },
          ],
        },
      },
      {
        path: '/app/settings/nature-cargo',
        name: 'app-settings-nature-cargo',
        redirect: '/app/settings/nature-cargo/new',
        component: () => import('@/views/app/admin/settings/cargoNature/index.vue'),
        children: [
          {
            path: '/app/settings/nature-cargo/new',
            name: 'app-settings-nature-cargo-new',
            component: () => import('@/views/app/admin/settings/cargoNature/add.vue'),
            meta: {
              pageTitle: 'Nature Cargo',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                // {
                //   text: 'Liste',
                //   to: '/admin/settings/nature-cargo/listing',
                // },
                {
                  text: 'Nouveau',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/app/settings/nature-cargo/listing',
            name: 'app-settings-nature-cargo-listing',
            component: () => import('@/views/app/admin/settings/cargoNature/listing.vue'),
            meta: {
              pageTitle: 'Paramètres',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                {
                  text: 'Liste',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/app/settings/quotation',
        name: 'app-settings-quotation',
        redirect: '/app/settings/quotation/new',
        component: () => import('@/views/app/admin/settings/quotation/index.vue'),
        children: [
          {
            path: '/app/settings/quotation/new',
            name: 'app-settings-quotation-new',
            component: () => import('@/views/app/admin/settings/quotation/add.vue'),
            meta: {
              pageTitle: 'Type de Cotation',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                // {
                //   text: 'Liste',
                //   to: '/admin/settings/nature-cargo/listing',
                // },
                {
                  text: 'Nouveau',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/app/settings/quotation/listing',
            name: 'app-settings-quotation-listing',
            component: () => import('@/views/app/admin/settings/quotation/listing.vue'),
            meta: {
              pageTitle: 'Type de cotation',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                {
                  text: 'Liste',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/app/settings/ship',
        name: 'app-settings-ship',
        component: () => import('@/views/app/admin/settings/ship/index.vue'),
        children: [
          {
            path: '/app/settings/ship/nature',
            name: 'app-settings-ship-nature',
            redirect: '/app/settings/ship/nature/add',
            component: () => import('@/views/app/admin/settings/ship/nature/index.vue'),
            children: [
              {
                path: '/app/settings/ship/nature/add',
                name: 'app-settings-ship-nature-add',
                component: () => import('@/views/app/admin/settings/ship/nature/add.vue'),
                meta: {
                  pageTitle: 'Nature Navire',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    // {
                    //   text: 'Liste',
                    //   to: '/admin/settings/nature-cargo/listing',
                    // },
                    {
                      text: 'Nouveau',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: '/app/settings/ship/nature/listing',
                name: 'app-settings-ship-nature-listing',
                component: () => import('@/views/app/admin/settings/ship/nature/listing.vue'),
                meta: {
                  pageTitle: 'Nature Navire',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    {
                      text: 'Liste',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
          {
            path: '/app/settings/ship/operation',
            name: 'app-settings-ship-operation',
            redirect: '/app/settings/ship/operation/add',
            component: () => import('@/views/app/admin/settings/ship/operation/index.vue'),
            children: [
              {
                path: '/app/settings/ship/operation/add',
                name: 'app-settings-ship-operation-add',
                component: () => import('@/views/app/admin/settings/ship/operation/add.vue'),
                meta: {
                  pageTitle: 'Type Mouvement Navire',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    // {
                    //   text: 'Liste',
                    //   to: '/admin/settings/nature-cargo/listing',
                    // },
                    {
                      text: 'Nouveau',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: '/app/settings/ship/operation/listing',
                name: 'app-settings-ship-operation-listing',
                component: () => import('@/views/app/admin/settings/ship/operation/listing.vue'),
                meta: {
                  pageTitle: 'Type Mouvement Navire',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    {
                      text: 'Liste',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
          {
            path: '/app/settings/ship/consignee',
            name: 'app-settings-ship-consignee',
            redirect: '/app/settings/ship/consignee/add',
            component: () => import('@/views/app/admin/settings/ship/consignee/index.vue'),
            children: [
              {
                path: '/app/settings/ship/consignee/add',
                name: 'app-settings-ship-consignee-add',
                component: () => import('@/views/app/admin/settings/ship/consignee/add.vue'),
                meta: {
                  pageTitle: 'Consignation',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    // {
                    //   text: 'Liste',
                    //   to: '/admin/settings/nature-cargo/listing',
                    // },
                    {
                      text: 'Nouveau',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: '/app/settings/ship/consignee/listing',
                name: 'app-settings-ship-consignee-listing',
                component: () => import('@/views/app/admin/settings/ship/consignee/listing.vue'),
                meta: {
                  pageTitle: 'Consignation',
                  breadcrumb: [
                    {
                      text: 'Paramètres',
                      to: '/app/settings',
                    },
                    {
                      text: 'Liste',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/app/settings/users',
        name: 'app-settings-users',
        redirect: '/app/settings/users/new',
        component: () => import('@/views/app/admin/settings/users/index.vue'),
        children: [
          {
            path: '/app/settings/users/new',
            name: 'app-settings-users-new',
            component: () => import('@/views/app/admin/settings/users/register.vue'),
            meta: {
              pageTitle: 'Utilisateur',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                // {
                //   text: 'Liste',
                //   to: '/admin/settings/nature-cargo/listing',
                // },
                {
                  text: 'Nouveau',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/app/settings/users/listing',
            name: 'app-settings-users-listing',
            component: () => import('@/views/app/admin/settings/users/manage.vue'),
            meta: {
              pageTitle: 'Utilisateur',
              breadcrumb: [
                {
                  text: 'Paramètres',
                  to: '/app/settings',
                },
                {
                  text: 'Liste',
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
]
