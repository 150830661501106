/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    shipNatureProcess: null,
    shipNatureSuccess: null,
    shipNatureError: null,

    shipListNatureProcess: null,
    shipListNatureSuccess: null,
    shipListNatureError: null,

    updateShipNatureProcess: null,
    updateShipNatureSuccess: null,
    updateShipNatureError: null,

    deleteShipNatureProcess: null,
    deleteShipNatureError: null,
    deleteShipNatureSuccess: null,
  },
  getters: {
    shipNatureProcess: state => state.shipNatureProcess,
    shipNatureSuccess: state => state.shipNatureSuccess,
    shipNatureError: state => state.shipNatureError,

    shipListNatureProcess: state => state.shipListNatureProcess,
    shipListNatureSuccess: state => state.shipListNatureSuccess,
    shipListNatureError: state => state.shipListNatureError,

    updateShipNatureProcess: state => state.updateShipNatureProcess,
    updateShipNatureSuccess: state => state.updateShipNatureSuccess,
    updateShipNatureError: state => state.updateShipNatureError,

    deleteShipNatureProcess: state => state.deleteShipNatureProcess,
    deleteShipNatureSuccess: state => state.deleteShipNatureSuccess,
    deleteShipNatureError: state => state.deleteShipNatureError,
  },
  mutations: {

    SHIP_NATURE_PROCESS(state, payload) {
      state.shipNatureProcess = payload
    },
    SHIP_NATURE_SUCCESS(state, payload) {
      state.shipNatureSuccess = payload
    },
    SHIP_NATURE_ERROR(state, payload) {
      state.shipNatureError = payload
    },

    CLEAR_SHIP_NATURE(state) {
      state.shipNatureError = null
      state.shipNatureProcess = null
      state.shipNatureSuccess = null
    },

    LISTSHIP_NATURE_PROCESS(state, payload) {
      state.shipListNatureProcess = payload
    },
    LISTSHIP_NATURE_SUCCESS(state, payload) {
      state.shipListNatureSuccess = payload
    },
    LISTSHIP_NATURE_ERROR(state, payload) {
      state.shipListNatureError = payload
    },

    CLEAR_LISTSHIP_NATURE(state) {
      state.shipListNatureError = null
      state.shipListNatureProcess = null
      state.shipListNatureSuccess = null
    },

    UPDATE_SHIP_NATURE_PROCESS(state, payload) {
      state.updateShipNatureProcess = payload
    },
    UPDATE_SHIP_NATURE_SUCCESS(state, payload) {
      state.updateShipNatureSuccess = payload
    },
    UPDATE_SHIP_NATURE_ERROR(state, payload) {
      state.updateShipNatureError = payload
    },
    CLEAR_UPDATE_SHIP_NATURE(state) {
      state.updateShipNatureError = null
      state.updateShipNatureProcess = null
      state.updateShipNatureSuccess = null
    },

    DELETE_SHIP_NATURE_PROCESS(state, payload) {
      state.deleteShipNatureProcess = payload
    },
    DELETE_SHIP_NATURE_SUCCESS(state, payload) {
      state.deleteShipNatureSuccess = payload
    },
    DELETE_SHIP_NATURE_ERROR(state, payload) {
      state.deleteShipNatureError = payload
    },
    CLEAR_DELETE_SHIP_NATURE(state) {
      state.deleteShipNatureSuccess = null
      state.deleteShipNatureProcess = null
      state.deleteShipNatureError = null
    },
  },
  actions: {
    shipNature({ commit, state }, payload) {
      commit('CLEAR_SHIP_NATURE')
      commit('SHIP_NATURE_PROCESS', true)
      const data = JSON.stringify({
        title: payload.title,
      })
      axios
        .post('/api/ship-nature/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('SHIP_NATURE_PROCESS', false)
          commit('SHIP_NATURE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('SHIP_NATURE_PROCESS', false)
          if (error.response) {
            commit('SHIP_NATURE_ERROR', error.response.data)
          } else if (error.request) {
            commit('SHIP_NATURE_ERROR', error.request)
          } else {
            commit('SHIP_NATURE_ERROR', error.message)
          }
        })
    },
    shipListNature({ commit }, payload) {
      commit('CLEAR_LISTSHIP_NATURE')
      commit('LISTSHIP_NATURE_PROCESS', true)
      const data = JSON.stringify({
        data_list: payload.dataList,
      })
      axios
        .post('/api/ship-nature/add-all', data)
        .then(res => {
          commit('LISTSHIP_NATURE_PROCESS', false)
          commit('LISTSHIP_NATURE_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('LISTSHIP_NATURE_PROCESS', false)
          if (error.response) {
            commit('LISTSHIP_NATURE_ERROR', error.response.data)
          } else if (error.request) {
            commit('LISTSHIP_NATURE_ERROR', error.request)
          } else {
            commit('LISTSHIP_NATURE_ERROR', error.message)
          }
        })
    },
    updateShipNature({ commit, state }, payload) {
      commit('CLEAR_UPDATE_SHIP_NATURE')
      commit('UPDATE_SHIP_NATURE_PROCESS', true)
      const data = JSON.stringify({
        title: payload.title,
      })
      axios({
        method: 'put',
        url: `/api/ship-nature/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_SHIP_NATURE_PROCESS', false)
          commit('UPDATE_SHIP_NATURE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_SHIP_NATURE_PROCESS', false)
          if (error.response) {
            commit('UPDATE_SHIP_NATURE_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_SHIP_NATURE_ERROR', error.request)
          } else {
            commit('UPDATE_SHIP_NATURE_ERROR', error.message)
          }
        })
    },
    deleteShipNature({ commit, state }, payload) {
      commit('CLEAR_DELETE_SHIP_NATURE')
      commit('DELETE_SHIP_NATURE_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/ship-nature/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_SHIP_NATURE_PROCESS', false)
          commit('DELETE_SHIP_NATURE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_SHIP_NATURE_PROCESS', false)
          if (error.response) {
            commit('DELETE_SHIP_NATURE_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_SHIP_NATURE_ERROR', error.request)
          } else {
            commit('DELETE_SHIP_NATURE_ERROR', error.message)
          }
        })
    },
  },
}
