/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    quotationTypeProcess: null,
    quotationTypeSuccess: null,
    quotationTypeError: null,

    quotationTypeListProcess: null,
    quotationTypeListSuccess: null,
    quotationTypeListError: null,

    updateQuotationTypeProcess: null,
    updateQuotationTypeSuccess: null,
    updateQuotationTypeError: null,

    deleteQuotationTypeProcess: null,
    deleteQuotationTypeError: null,
    deleteQuotationTypeSuccess: null,
  },
  getters: {
    quotationTypeProcess: state => state.quotationTypeProcess,
    quotationTypeSuccess: state => state.quotationTypeSuccess,
    quotationTypeError: state => state.quotationTypeError,

    quotationTypeListProcess: state => state.quotationTypeListProcess,
    quotationTypeListSuccess: state => state.quotationTypeListSuccess,
    quotationTypeListError: state => state.quotationTypeListError,

    updateQuotationTypeProcess: state => state.updateQuotationTypeProcess,
    updateQuotationTypeSuccess: state => state.updateQuotationTypeSuccess,
    updateQuotationTypeError: state => state.updateQuotationTypeError,

    deleteQuotationTypeProcess: state => state.deleteQuotationTypeProcess,
    deleteQuotationTypeSuccess: state => state.deleteQuotationTypeSuccess,
    deleteQuotationTypeError: state => state.deleteQuotationTypeError,
  },
  mutations: {

    TYPE_QUOTATION_PROCESS(state, payload) {
      state.quotationTypeProcess = payload
    },
    TYPE_QUOTATION_SUCCESS(state, payload) {
      state.quotationTypeSuccess = payload
    },
    TYPE_QUOTATION_ERROR(state, payload) {
      state.quotationTypeError = payload
    },

    CLEAR_TYPE_QUOTATION(state) {
      state.quotationTypeError = null
      state.quotationTypeProcess = null
      state.quotationTypeSuccess = null
    },

    TYPELIST_QUOTATION_PROCESS(state, payload) {
      state.quotationTypeListProcess = payload
    },
    TYPELIST_QUOTATION_SUCCESS(state, payload) {
      state.quotationTypeListSuccess = payload
    },
    TYPELIST_QUOTATION_ERROR(state, payload) {
      state.quotationTypeListError = payload
    },

    CLEAR_TYPELIST_QUOTATION(state) {
      state.quotationTypeListError = null
      state.quotationTypeListProcess = null
      state.quotationTypeListSuccess = null
    },

    UPDATE_QUOTATION_TYPE_PROCESS(state, payload) {
      state.updateQuotationTypeProcess = payload
    },
    UPDATE_QUOTATION_TYPE_SUCCESS(state, payload) {
      state.updateQuotationTypeSuccess = payload
    },
    UPDATE_QUOTATION_TYPE_ERROR(state, payload) {
      state.updateQuotationTypeError = payload
    },
    CLEAR_UPDATE_QUOTATION_TYPE(state) {
      state.updateQuotationTypeError = null
      state.updateQuotationTypeProcess = null
      state.updateQuotationTypeSuccess = null
    },

    DELETE_QUOTATION_TYPE_PROCESS(state, payload) {
      state.deleteQuotationTypeProcess = payload
    },
    DELETE_QUOTATION_TYPE_SUCCESS(state, payload) {
      state.deleteQuotationTypeSuccess = payload
    },
    DELETE_QUOTATION_TYPE_ERROR(state, payload) {
      state.deleteQuotationTypeError = payload
    },
    CLEAR_DELETE_QUOTATION_TYPE(state) {
      state.deleteQuotationTypeSuccess = null
      state.deleteQuotationTypeProcess = null
      state.deleteQuotationTypeError = null
    },
  },
  actions: {
    newQuotationType({ commit, state }, payload) {
      commit('CLEAR_TYPE_QUOTATION')
      commit('TYPE_QUOTATION_PROCESS', true)
      const data = JSON.stringify({
        title: payload.title,
      })
      axios
        .post('/api/quotation-operation-type/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('TYPE_QUOTATION_PROCESS', false)
          commit('TYPE_QUOTATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('TYPE_QUOTATION_PROCESS', false)
          if (error.response) {
            commit('TYPE_QUOTATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('TYPE_QUOTATION_ERROR', error.request)
          } else {
            commit('TYPE_QUOTATION_ERROR', error.message)
          }
        })
    },
    newListQuotationType({ commit }, payload) {
      commit('CLEAR_TYPELIST_QUOTATION')
      commit('TYPELIST_QUOTATION_PROCESS', true)
      const data = JSON.stringify({
        data_list: payload.dataList,
      })
      axios
        .post('​/api​/quotation-operation-type​/add-all', data)
        .then(res => {
          commit('TYPELIST_QUOTATION_PROCESS', false)
          commit('TYPELIST_QUOTATION_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('TYPELIST_QUOTATION_PROCESS', false)
          if (error.response) {
            commit('TYPELIST_QUOTATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('TYPELIST_QUOTATION_ERROR', error.request)
          } else {
            commit('TYPELIST_QUOTATION_ERROR', error.message)
          }
        })
    },
    updateQuotationType({ commit, state }, payload) {
      commit('CLEAR_UPDATE_QUOTATION_TYPE')
      commit('UPDATE_QUOTATION_TYPE_PROCESS', true)
      const data = JSON.stringify({
        title: payload.name,
      })
      axios({
        method: 'put',
        url: `/api/quotation-operation-type/update/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
        data,
      })
        .then(res => {
          commit('UPDATE_QUOTATION_TYPE_PROCESS', false)
          commit('UPDATE_QUOTATION_TYPE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_QUOTATION_TYPE_PROCESS', false)
          if (error.response) {
            commit('UPDATE_QUOTATION_TYPE_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_QUOTATION_TYPE_ERROR', error.request)
          } else {
            commit('UPDATE_QUOTATION_TYPE_ERROR', error.message)
          }
        })
    },
    deleteQuotationType({ commit, state }, payload) {
      commit('CLEAR_DELETE_QUOTATION_TYPE')
      commit('DELETE_QUOTATION_TYPE_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/quotation-operation-type/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_QUOTATION_TYPE_PROCESS', false)
          commit('DELETE_QUOTATION_TYPE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_QUOTATION_TYPE_PROCESS', false)
          if (error.response) {
            commit('DELETE_QUOTATION_TYPE_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_QUOTATION_TYPE_ERROR', error.request)
          } else {
            commit('DELETE_QUOTATION_TYPE_ERROR', error.message)
          }
        })
    },
  },
}
