export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: '/reset-password/:token/:id',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
    },
  },
  {
    path: '/confirm-account/:token/:id',
    name: 'confirm-account',
    component: () => import('@/views/auth/ConfirmAccount.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
    },
  },
]
