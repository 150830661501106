import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    newsProcess: null,
    newsSuccess: null,
    newsError: null,

    updateNewsProcess: null,
    updateNewsSuccess: null,
    updateNewsError: null,

    deleteNewsProcess: null,
    deleteNewsError: null,
    deleteNewsSuccess: null,
  },
  getters: {
    newsProcess: state => state.newsProcess,
    newsSuccess: state => state.newsSuccess,
    newsError: state => state.newsError,

    updateNewsProcess: state => state.updateNewsProcess,
    updateNewsSuccess: state => state.updateNewsSuccess,
    updateNewsError: state => state.updateNewsError,

    deleteNewsProcess: state => state.deleteNewsProcess,
    deleteNewsSuccess: state => state.deleteNewsSuccess,
    deleteNewsError: state => state.deleteNewsError,
  },
  mutations: {

    NEWS_PROCESS(state, payload) {
      state.newsProcess = payload
    },
    NEWS_SUCCESS(state, payload) {
      state.newsSuccess = payload
    },
    NEWS_ERROR(state, payload) {
      state.newsError = payload
    },

    CLEAR_NEWS(state) {
      state.newsError = null
      state.newsProcess = null
      state.newsSuccess = null
    },

    UPDATE_NEWS_PROCESS(state, payload) {
      state.updateNewsProcess = payload
    },
    UPDATE_NEWS_SUCCESS(state, payload) {
      state.updateNewsSuccess = payload
    },
    UPDATE_NEWS_ERROR(state, payload) {
      state.updateNewsError = payload
    },
    CLEAR_UPDATE_NEWS(state) {
      state.updateNewsError = null
      state.updateNewsProcess = null
      state.updateNewsSuccess = null
    },

    DELETE_NEWS_PROCESS(state, payload) {
      state.deleteNewsProcess = payload
    },
    DELETE_NEWS_SUCCESS(state, payload) {
      state.deleteNewsSuccess = payload
    },
    DELETE_NEWS_ERROR(state, payload) {
      state.deleteNewsError = payload
    },
    CLEAR_DELETE_NEWS(state) {
      state.deleteNewsSuccess = null
      state.deleteNewsProcess = null
      state.deleteNewsError = null
    },
  },
  actions: {
    addNews({ commit }, payload) {
      commit('CLEAR_NEWS')
      commit('NEWS_PROCESS', true)
      const formdata = new FormData()
      formdata.append('title', payload.title)
      formdata.append('description', payload.description)
      formdata.append('limit_date', '')
      const files = payload.file
      for (let i = 0; i < files.length; i += 1) {
        formdata.append('file', files[i])
      }
      axios({
        method: 'post',
        data: formdata,
        url: '/api/micro/news/add',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('NEWS_PROCESS', false)
          commit('NEWS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('NEWS_PROCESS', false)
          if (error.response) {
            commit('NEWS_ERROR', error.response.data)
          } else if (error.request) {
            commit('NEWS_ERROR', error.request)
          } else {
            commit('NEWS_ERROR', error.message)
          }
        })
    },
    updateNews({ commit }, payload) {
      commit('CLEAR_UPDATE_NEWS')
      commit('UPDATE_NEWS_PROCESS', true)
      const data = JSON.stringify({
        title: payload.title,
        description: payload.description,
        limit_date: payload.limitDate,
        file: payload.files,
        medias_id: payload.medias,
      })
      axios({
        method: 'put',
        url: `/api/micro/news/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_NEWS_PROCESS', false)
          commit('UPDATE_NEWS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_NEWS_PROCESS', false)
          if (error.response) {
            commit('UPDATE_NEWS_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_NEWS_ERROR', error.request)
          } else {
            commit('UPDATE_NEWS_ERROR', error.message)
          }
        })
    },
    deleteNews({ commit }, payload) {
      commit('CLEAR_DELETE_NEWS')
      commit('DELETE_NEWS_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/micro/news/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_NEWS_PROCESS', false)
          commit('DELETE_NEWS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_NEWS_PROCESS', false)
          if (error.response) {
            commit('DELETE_NEWS_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_NEWS_ERROR', error.request)
          } else {
            commit('DELETE_NEWS_ERROR', error.message)
          }
        })
    },
  },
}
