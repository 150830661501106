/* eslint-disable class-methods-use-this */
import encrypt from '@/libs/encrypt'

const USER_TOKEN = 'Aa3pp6-=&=ADa@88gqMjstn5Ns#GJn#hAXM-5s$Rrn#p9=8ST-KT6*VR8QBZ*!QEPfby'

class UserToken {
  set(value) {
    const data = encrypt.encodeToken(value)
    return window.localStorage.setItem(USER_TOKEN, JSON.stringify(data))
  }

  get() {
    return window.localStorage.getItem(USER_TOKEN) != null
      ? encrypt.decodeToken(JSON.parse(window.localStorage.getItem(USER_TOKEN)))
      : null
  }

  decodeToken() {
    if (this.get()) {
      const token = this.get()
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
          .join(''),
      )

      const expirationDate = JSON.parse(jsonPayload)
      return expirationDate.exp
    } return Date.parse(new Date()) / 1000
  }

  remove() {
    return window.localStorage.removeItem(USER_TOKEN)
  }
}

export default new UserToken()
