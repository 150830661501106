import Vue from 'vue'

import axios from 'axios'
// import { logoutUser } from '@/auth/utils'

const axiosIns = axios.create({
  baseURL: 'https://api-backoffice.sobemap.com/',
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
    if (response && response.status === 401) {
      window.location.assign('/login')
      return Promise.reject(error)
    }
    if (response && response.status === 404) {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
