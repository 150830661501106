import token from '@/localStorage/token'
import user from '@/localStorage/user'

const currentUser = user.get() != null ? user.get() : null

// Check is user have valid token
export const isActiveToken = () => {
  const userToken = token.get() != null ? token.get() : null

  if (userToken) {
    const time = Date.parse(new Date()) / 1000
    const expireDate = token.decodeToken()

    if (time > expireDate) {
      return false
    }
    return true
  }
  return false
}

export const logoutUser = () => {
  token.remove()
  user.remove()
}

// redirect user using roles
export const getHomeRouteForLoggedInUser = () => {
  const userData = user.get() != null ? user.get() : null
  if (userData) {
    const { roles } = userData
    if (roles?.find(role => [
      'ROLE_ADMIN',
      'ROLE_SUPER_ADMIN',
      'ROLE_USER',
      'ROLE_DIR_COM',
      'ROLE_PREVISION_NAVIRE',
      'ROLE_OPERATION',
    ]?.includes(role))) {
      return { name: 'app-home' }
    }
  }
  return { name: 'login' }
}

// get user roles
export const getPrivileges = () => {
  const userData = user.get() != null ? user.get() : null
  if (userData) {
    const { roles } = userData
    if (roles.includes('ROLE_ADMIN')) {
      return 'admin'
    }
    if (roles.includes('ROLE_USER')) {
      return 'simpleUser'
    }
  }
  return null
}

export const canAccess = accessRoles => {
  if (currentUser) {
    const { roles } = currentUser
    if (roles.find(role => accessRoles.includes(role))) {
      return true
    }
    return false
  }
  return false
}
