/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    shipMovementOperationProcess: null,
    shipMovementOperationSuccess: null,
    shipMovementOperationError: null,

    shipListMovementOperationProcess: null,
    shipListMovementOperationSuccess: null,
    shipListMovementOperationError: null,

    updateShipMovementOperationProcess: null,
    updateShipMovementOperationSuccess: null,
    updateShipMovementOperationError: null,

    deleteShipMovementOperationProcess: null,
    deleteShipMovementOperationError: null,
    deleteShipMovementOperationSuccess: null,
  },
  getters: {
    shipMovementOperationProcess: state => state.shipMovementOperationProcess,
    shipMovementOperationSuccess: state => state.shipMovementOperationSuccess,
    shipMovementOperationError: state => state.shipMovementOperationError,

    shipListMovementOperationProcess: state => state.shipListMovementOperationProcess,
    shipListMovementOperationSuccess: state => state.shipListMovementOperationSuccess,
    shipListMovementOperationError: state => state.shipListMovementOperationError,

    updateShipMovementOperationProcess: state => state.updateShipMovementOperationProcess,
    updateShipMovementOperationSuccess: state => state.updateShipMovementOperationSuccess,
    updateShipMovementOperationError: state => state.updateShipMovementOperationError,

    deleteShipMovementOperationProcess: state => state.deleteShipMovementOperationProcess,
    deleteShipMovementOperationSuccess: state => state.deleteShipMovementOperationSuccess,
    deleteShipMovementOperationError: state => state.deleteShipMovementOperationError,
  },
  mutations: {

    LISTSHIP_MOVEMENT_OPERATION_PROCESS(state, payload) {
      state.shipListMovementOperationProcess = payload
    },
    LISTSHIP_MOVEMENT_OPERATION_SUCCESS(state, payload) {
      state.shipListMovementOperationSuccess = payload
    },
    LISTSHIP_MOVEMENT_OPERATION_ERROR(state, payload) {
      state.shipListMovementOperationError = payload
    },

    CLEAR_LISTSHIP_MOVEMENT_OPERATION(state) {
      state.shipListMovementOperationError = null
      state.shipListMovementOperationProcess = null
      state.shipListMovementOperationSuccess = null
    },

    SHIP_MOVEMENT_OPERATION_PROCESS(state, payload) {
      state.shipMovementOperationProcess = payload
    },
    SHIP_MOVEMENT_OPERATION_SUCCESS(state, payload) {
      state.shipMovementOperationSuccess = payload
    },
    SHIP_MOVEMENT_OPERATION_ERROR(state, payload) {
      state.shipMovementOperationError = payload
    },

    CLEAR_SHIP_MOVEMENT_OPERATION(state) {
      state.shipMovementOperationError = null
      state.shipMovementOperationProcess = null
      state.shipMovementOperationSuccess = null
    },

    UPDATE_SHIP_MOVEMENT_OPERATION_PROCESS(state, payload) {
      state.updateShipMovementOperationProcess = payload
    },
    UPDATE_SHIP_MOVEMENT_OPERATION_SUCCESS(state, payload) {
      state.updateShipMovementOperationSuccess = payload
    },
    UPDATE_SHIP_MOVEMENT_OPERATION_ERROR(state, payload) {
      state.updateShipMovementOperationError = payload
    },
    CLEAR_UPDATE_SHIP_MOVEMENT_OPERATION(state) {
      state.updateShipMovementOperationError = null
      state.updateShipMovementOperationProcess = null
      state.updateShipMovementOperationSuccess = null
    },

    DELETE_SHIP_MOVEMENT_OPERATION_PROCESS(state, payload) {
      state.deleteShipMovementOperationProcess = payload
    },
    DELETE_SHIP_MOVEMENT_OPERATION_SUCCESS(state, payload) {
      state.deleteShipMovementOperationSuccess = payload
    },
    DELETE_SHIP_MOVEMENT_OPERATION_ERROR(state, payload) {
      state.deleteShipMovementOperationError = payload
    },
    CLEAR_DELETE_SHIP_MOVEMENT_OPERATION(state) {
      state.deleteShipMovementOperationSuccess = null
      state.deleteShipMovementOperationProcess = null
      state.deleteShipMovementOperationError = null
    },
  },
  actions: {
    shipMovementOperation({ commit, state }, payload) {
      commit('CLEAR_SHIP_MOVEMENT_OPERATION')
      commit('SHIP_MOVEMENT_OPERATION_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
      })
      axios
        .post('/api/ship-movement-operation/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('SHIP_MOVEMENT_OPERATION_PROCESS', false)
          commit('SHIP_MOVEMENT_OPERATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('SHIP_MOVEMENT_OPERATION_PROCESS', false)
          if (error.response) {
            commit('SHIP_MOVEMENT_OPERATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('SHIP_MOVEMENT_OPERATION_ERROR', error.request)
          } else {
            commit('SHIP_MOVEMENT_OPERATION_ERROR', error.message)
          }
        })
    },
    shipListMovementOperation({ commit }, payload) {
      commit('CLEAR_LISTSHIP_MOVEMENT_OPERATION')
      commit('LISTSHIP_MOVEMENT_OPERATION_PROCESS', true)
      const data = JSON.stringify({
        data_list: payload.dataList,
      })
      axios
        .post('/api/ship-movement-operation/add-all', data)
        .then(res => {
          commit('LISTSHIP_MOVEMENT_OPERATION_PROCESS', false)
          commit('LISTSHIP_MOVEMENT_OPERATION_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('LISTSHIP_MOVEMENT_OPERATION_PROCESS', false)
          if (error.response) {
            commit('LISTSHIP_MOVEMENT_OPERATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('LISTSHIP_MOVEMENT_OPERATION_ERROR', error.request)
          } else {
            commit('LISTSHIP_MOVEMENT_OPERATION_ERROR', error.message)
          }
        })
    },
    updateShipMovementOperation({ commit, state }, payload) {
      commit('CLEAR_UPDATE_SHIP_MOVEMENT_OPERATION')
      commit('UPDATE_SHIP_MOVEMENT_OPERATION_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
      })
      axios({
        method: 'put',
        url: `/api/ship-movement-operation/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_SHIP_MOVEMENT_OPERATION_PROCESS', false)
          commit('UPDATE_SHIP_MOVEMENT_OPERATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_SHIP_MOVEMENT_OPERATION_PROCESS', false)
          if (error.response) {
            commit('UPDATE_SHIP_MOVEMENT_OPERATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_SHIP_MOVEMENT_OPERATION_ERROR', error.request)
          } else {
            commit('UPDATE_SHIP_MOVEMENT_OPERATION_ERROR', error.message)
          }
        })
    },
    deleteShipMovementOperation({ commit, state }, payload) {
      commit('CLEAR_DELETE_SHIP_MOVEMENT_OPERATION')
      commit('DELETE_SHIP_MOVEMENT_OPERATION_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/ship-movement-operation/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_SHIP_MOVEMENT_OPERATION_PROCESS', false)
          commit('DELETE_SHIP_MOVEMENT_OPERATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_SHIP_MOVEMENT_OPERATION_PROCESS', false)
          if (error.response) {
            commit('DELETE_SHIP_MOVEMENT_OPERATION_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_SHIP_MOVEMENT_OPERATION_ERROR', error.request)
          } else {
            commit('DELETE_SHIP_MOVEMENT_OPERATION_ERROR', error.message)
          }
        })
    },
  },
}
