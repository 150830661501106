import axios from '@/libs/axios'
import userToken from '@/localStorage/token'
import user from '@/localStorage/user'
import handleError from '../../helpers/errorHandler'

export default {
  state: {
    user: user.get() != null ? user.get() : null,
    token: userToken.get() != null ? userToken.get() : null,
    loginProcess: null,
    loginSuccess: null,
    loginError: null,

    resetProcess: null,
    resetSuccess: null,
    resetError: null,

    registerProcess: null,
    registerError: null,
    registerSuccess: null,

    initResetProcess: null,
    initResetError: null,
    initResetSuccess: null,

    confirmEmailProcess: null,
    confirmEmailError: null,
    confirmEmailSuccess: null,

    verifyEmailProcess: null,
    verifyEmailError: null,
    verifyEmailSuccess: null,

    deleteProcess: null,
    deleteError: null,
    deleteSuccess: null,

    loggedIn: false,
  },
  getters: {
    token: state => state.token,
    currentUser: state => state.user,
    loginProcess: state => state.loginProcess,
    loginSuccess: state => state.loginSuccess,
    loginError: state => state.loginError,

    resetProcess: state => state.resetProcess,
    resetSuccess: state => state.resetSuccess,
    resetError: state => state.resetError,

    registerProcess: state => state.registerProcess,
    registerSuccess: state => state.registerSuccess,
    registerError: state => state.registerError,

    initResetProcess: state => state.initResetProcess,
    initResetError: state => state.initResetError,
    initResetSuccess: state => state.initResetSuccess,

    confirmEmailProcess: state => state.confirmEmailProcess,
    confirmEmailError: state => state.confirmEmailError,
    confirmEmailSuccess: state => state.confirmEmailSuccess,

    verifyEmailProcess: state => state.verifyEmailProcess,
    verifyEmailError: state => state.verifyEmailError,
    verifyEmailSuccess: state => state.verifyEmailSuccess,

    deleteProcess: state => state.deleteProcess,
    deleteError: state => state.deleteError,
    deleteSuccess: state => state.deleteSuccess,
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },

    LOGIN_PROCESS(state, payload) {
      state.loginProcess = payload
    },
    LOGIN_SUCCESS(state, payload) {
      state.loginSuccess = payload
    },
    LOGIN_ERROR(state, payload) {
      state.loginError = payload
    },

    CLEAR_LOGIN(state) {
      state.loginError = null
      state.loginProcess = null
      state.loginSuccess = null
    },

    RESET_PROCESS(state, payload) {
      state.resetProcess = payload
    },
    RESET_SUCCESS(state, payload) {
      state.resetSuccess = payload
    },
    RESET_ERROR(state, payload) {
      state.resetError = payload
    },
    CLEAR_RESET(state) {
      state.resetError = null
      state.resetProcess = null
      state.resetSuccess = null
    },

    // ResetPassword state

    RESET_PASSWORD_PROCESS(state, payload) {
      state.initResetProcess = payload
    },
    RESET_PASSWORD_SUCCESS(state, payload) {
      state.initResetSuccess = payload
    },
    RESET_PASSWORD_ERROR(state, payload) {
      state.initResetError = payload
    },
    RESET_PASSWORD_CLEAR(state) {
      state.initResetError = null
      state.initResetSuccess = null
      state.initResetProcess = null
    },

    REGISTER_PROCESS(state, payload) {
      state.registerProcess = payload
    },
    REGISTER_SUCCESS(state, payload) {
      state.registerSuccess = payload
    },
    REGISTER_ERROR(state, payload) {
      state.registerError = payload
    },
    CLEAR_REGISTER(state) {
      state.registerError = null
      state.registerProcess = null
      state.registerSuccess = null
    },

    // Confirm Email

    CONFIRM_PROCESS(state, payload) {
      state.confirmEmailProcess = payload
    },
    CONFIRM_SUCCESS(state, payload) {
      state.confirmEmailSuccess = payload
    },
    CONFIRM_ERROR(state, payload) {
      state.confirmEmailError = payload
    },
    CLEAR_CONFIRM(state) {
      state.confirmEmailError = null
      state.confirmEmailProcess = null
      state.confirmEmailSuccess = null
    },

    // Verify User

    VERIFY_PROCESS(state, payload) {
      state.verifyEmailProcess = payload
    },
    VERIFY_SUCCESS(state, payload) {
      state.verifyEmailSuccess = payload
    },
    VERIFY_ERROR(state, payload) {
      state.verifyEmailError = payload
    },
    CLEAR_VERIFY(state) {
      state.verifyEmailError = null
      state.verifyEmailSuccess = null
      state.verifyEmailProcess = null
    },
    DELETE_PROCESS(state, payload) {
      state.deleteProcess = payload
    },
    DELETE_SUCCESS(state, payload) {
      state.deleteSuccess = payload
    },
    DELETE_ERROR(state, payload) {
      state.deleteError = payload
    },
    CLEAR_DELETE_STATES(state) {
      state.deleteError = null
      state.deleteProcess = null
      state.deleteSuccess = null
    },
  },
  actions: {
    loginUser({ commit, dispatch }, payload) {
      commit('CLEAR_LOGIN')
      commit('LOGIN_PROCESS', true)
      const data = JSON.stringify({
        username: payload.username,
        password: payload.password,
      })

      axios
        .post('/api/oauth/login-check', data)
        .then(res => {
          const { token } = res.data
          commit('SET_TOKEN', token)
          userToken.set(token)
          dispatch('getUser')
        })
        .catch(error => {
          commit('LOGIN_PROCESS', false)
          commit('LOGIN_ERROR', handleError(error))
        })
    },
    async getUser({ commit, state, dispatch }) {
      axios
        .get('/api/oauth/user-info', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          const { data } = res
          if (data.active) {
            commit('SET_USER', data)
            user.set(data)
            commit('LOGIN_SUCCESS', res.data)
            commit('LOGIN_PROCESS', false)
          } else {
            userToken.remove()
            commit('LOGIN_PROCESS', false)
            commit('LOGIN_ERROR', 1)
            const dat = {
              email: data.email,
              callback_url: `${window.location.protocol}//${window.location.hostname}/confirm-account`,
            }
            dispatch('verifyUser', dat)
          }
        })
        .catch(error => {
          commit('LOGIN_PROCESS', false)
          commit('LOGIN_ERROR', handleError(error))
        })
    },
    verifyUser({ commit, state }, payload) {
      commit('CLEAR_VERIFY')
      commit('VERIFY_PROCESS', true)
      const data = JSON.stringify(payload)
      axios
        .post('/api/oauth/verify-user-active', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          userToken.remove()
          commit('SET_TOKEN', null)
          commit('VERIFY_PROCESS', false)
          commit('VERIFY_SUCCESS', res.data)
        })
        .catch(error => {
          userToken.remove()
          commit('SET_TOKEN', null)
          commit('VERIFY_PROCESS', false)
          commit('VERIFY_ERROR', handleError(error))
        })
    },

    confirmUserEmail({ commit, state }, payload) {
      commit('CLEAR_CONFIRM')
      commit('CONFIRM_PROCESS', true)
      const data = JSON.stringify({
        id: payload.id,
        token: payload.token,
        call_back_url: `${window.location.protocol}//${window.location.hostname}/confirm-account`,
      })
      axios
        .post('/api/oauth/confirm-account', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('CONFIRM_PROCESS', false)
          commit('CONFIRM_SUCCESS', res.data)
        })
        .catch(error => {
          commit('CONFIRM_PROCESS', false)
          commit('CONFIRM_ERROR', handleError(error))
        })
    },

    updatePassword({ commit, state }, payload) {
      commit('CLEAR_LOGIN')
      commit('LOGIN_PROCESS', true)
      const data = JSON.stringify({
        first_password: payload.firstPassword,
        second_password: payload.secondPassword,
      })
      axios
        .post(`/api/oauth/update-password/${payload?.id}`, data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('LOGIN_PROCESS', false)
          commit('LOGIN_SUCCESS', res.data)
        })
        .catch(error => {
          commit('LOGIN_PROCESS', false)
          commit('LOGIN_ERROR', handleError(error))
        })
    },
    setNewPassword({ state, commit }, payload) {
      commit('CLEAR_RESET')
      commit('RESET_PROCESS', true)
      const data = JSON.stringify({
        first_password: payload.first_password,
        second_password: payload.second_password,
      })
      axios
        .post(`/api/oauth/update-password/${payload.id}`, data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('RESET_PROCESS', false)
          commit('RESET_SUCCESS', res.data)
        })
        .catch(error => {
          commit('RESET_PROCESS', false)
          commit('RESET_ERROR', handleError(error))
        })
    },
    initPassReset({ commit }, payload) {
      commit('CLEAR_RESET')
      commit('RESET_PROCESS', true)
      const data = JSON.stringify({
        email: payload.email,
        callback_url: `${window.location.protocol}//${window.location.hostname}/reset-password`,
      })
      axios
        .post('/api/oauth/init-reset-password', data)
        .then(res => {
          commit('RESET_PROCESS', false)
          commit('RESET_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('RESET_PROCESS', false)
          commit('RESET_ERROR', handleError(error))
        })
    },
    resetPassword({ commit }, payload) {
      commit('RESET_PASSWORD_CLEAR')
      commit('RESET_PASSWORD_PROCESS', true)
      const data = JSON.stringify({
        id: payload.id,
        token: payload.token,
        first_password: payload.password,
        second_password: payload.secondPassword,
        callback_url: `${window.location.protocol}//${window.location.hostname}`,
      })
      axios
        .post('/api/oauth/set-new-password', data)
        .then(res => {
          commit('RESET_PASSWORD_PROCESS', false)
          commit('RESET_PASSWORD_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('RESET_PASSWORD_PROCESS', false)
          commit('RESET_PASSWORD_ERROR', handleError(error))
        })
    },
    registerUser({ commit, state }, payload) {
      commit('CLEAR_REGISTER')
      commit('REGISTER_PROCESS', true)
      const data = JSON.stringify(payload)
      axios
        .post('/api/oauth/register', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('REGISTER_PROCESS', false)
          commit('REGISTER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('REGISTER_PROCESS', false)
          commit('REGISTER_ERROR', handleError(error))
        })
    },
    updateUser({ commit, state }, payload) {
      commit('CLEAR_REGISTER')
      commit('REGISTER_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'PUT',
        url: '/api/oauth/update',
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        data,
      })
        .then(res => {
          commit('REGISTER_PROCESS', false)
          commit('REGISTER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('REGISTER_PROCESS', false)
          commit('REGISTER_ERROR', handleError(error))
        })
    },
    deleteUser({ commit, state }, payload) {
      commit('CLEAR_DELETE_STATES')
      commit('DELETE_PROCESS', true)
      axios({
        method: 'DELETE',
        url: `/api/oauth/delete-user/${payload}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then(res => {
          commit('DELETE_PROCESS', false)
          commit('DELETE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_PROCESS', false)
          commit('DELETE_ERROR', handleError(error))
        })
    },
  },
}
