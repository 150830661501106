const handleError = error => {
  let currentError = {
    code: error.code,
    message: error.message,
  }

  if (error.response) {
    if (error.response.data) {
      currentError = {
        code: error.response.status,
        message:
          error.response.data.message
          || error.response.data.error_description
          || error.response.data.error
          || error.response.data.profileClient,
      }
      return currentError
    }
  } else if (error.request) {
    if (error.request.response) {
      currentError = {
        code: error.request.status,
        message: error.request.statusText,
      }
      return currentError
    }
  } else {
    currentError = {
      code: error.message,
      message: error.message,
    }
    return currentError
  }
  return currentError
}

export default handleError
