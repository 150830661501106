/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    shipMovementProcess: null,
    shipMovementSuccess: null,
    shipMovementError: null,

    updateShipMovementProcess: null,
    updateShipMovementSuccess: null,
    updateShipMovementError: null,

    deleteShipMovementProcess: null,
    deleteShipMovementError: null,
    deleteShipMovementSuccess: null,
  },
  getters: {
    shipMovementProcess: state => state.shipMovementProcess,
    shipMovementSuccess: state => state.shipMovementSuccess,
    shipMovementError: state => state.shipMovementError,

    updateShipMovementProcess: state => state.updateShipMovementProcess,
    updateShipMovementSuccess: state => state.updateShipMovementSuccess,
    updateShipMovementError: state => state.updateShipMovementError,

    deleteShipMovementProcess: state => state.deleteShipMovementProcess,
    deleteShipMovementSuccess: state => state.deleteShipMovementSuccess,
    deleteShipMovementError: state => state.deleteShipMovementError,
  },
  mutations: {

    SHIP_MOVEMENT_PROCESS(state, payload) {
      state.shipMovementProcess = payload
    },
    SHIP_MOVEMENT_SUCCESS(state, payload) {
      state.shipMovementSuccess = payload
    },
    SHIP_MOVEMENT_ERROR(state, payload) {
      state.shipMovementError = payload
    },

    CLEAR_SHIP_MOVEMENT(state) {
      state.shipMovementError = null
      state.shipMovementProcess = null
      state.shipMovementSuccess = null
    },

    UPDATE_SHIP_MOVEMENT_PROCESS(state, payload) {
      state.updateShipMovementProcess = payload
    },
    UPDATE_SHIP_MOVEMENT_SUCCESS(state, payload) {
      state.updateShipMovementSuccess = payload
    },
    UPDATE_SHIP_MOVEMENT_ERROR(state, payload) {
      state.updateShipMovementError = payload
    },
    CLEAR_UPDATE_SHIP_MOVEMENT(state) {
      state.updateShipMovementError = null
      state.updateShipMovementProcess = null
      state.updateShipMovementSuccess = null
    },

    DELETE_SHIP_MOVEMENT_PROCESS(state, payload) {
      state.deleteShipMovementProcess = payload
    },
    DELETE_SHIP_MOVEMENT_SUCCESS(state, payload) {
      state.deleteShipMovementSuccess = payload
    },
    DELETE_SHIP_MOVEMENT_ERROR(state, payload) {
      state.deleteShipMovementError = payload
    },
    CLEAR_DELETE_SHIP_MOVEMENT(state) {
      state.deleteShipMovementSuccess = null
      state.deleteShipMovementProcess = null
      state.deleteShipMovementError = null
    },
  },
  actions: {
    shipMovement({ commit, state }, payload) {
      commit('CLEAR_SHIP_MOVEMENT')
      commit('SHIP_MOVEMENT_PROCESS', true)
      const data = JSON.stringify({
        ship_number: payload.shipNumber,
        ship_name: payload.shipName,
        ship_consignee_id: payload.shipConsignee,
        nature_cargo_id: payload.natureCargo,
        arrival_date: payload.arrivalDate,
        type_of_operation_id: payload.typeOperation,
        state: payload.state,
      })
      axios
        .post('/api/ship-movement/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('SHIP_MOVEMENT_PROCESS', false)
          commit('SHIP_MOVEMENT_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('SHIP_MOVEMENT_PROCESS', false)
          if (error.response) {
            commit('SHIP_MOVEMENT_ERROR', error.response.data)
          } else if (error.request) {
            commit('SHIP_MOVEMENT_ERROR', error.request)
          } else {
            commit('SHIP_MOVEMENT_ERROR', error.message)
          }
        })
    },
    updateShipMovement({ commit, state }, payload) {
      commit('CLEAR_UPDATE_SHIP_MOVEMENT')
      commit('UPDATE_SHIP_MOVEMENT_PROCESS', true)
      const data = JSON.stringify({
        ship_number: payload.shipNumber,
        ship_name: payload.shipName,
        ship_consignee_id: payload.shipConsignee,
        nature_cargo_id: payload.natureCargo,
        arrival_date: payload.arrivalDate,
        type_of_operation_id: payload.typeOperation,
        state: payload.state,
      })
      axios({
        method: 'put',
        url: `/api/ship-movement/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_SHIP_MOVEMENT_PROCESS', false)
          commit('UPDATE_SHIP_MOVEMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_SHIP_MOVEMENT_PROCESS', false)
          if (error.response) {
            commit('UPDATE_SHIP_MOVEMENT_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_SHIP_MOVEMENT_ERROR', error.request)
          } else {
            commit('UPDATE_SHIP_MOVEMENT_ERROR', error.message)
          }
        })
    },
    deleteShipMovement({ commit, state }, payload) {
      commit('CLEAR_DELETE_SHIP_MOVEMENT')
      commit('DELETE_SHIP_MOVEMENT_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/ship-movement/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_SHIP_MOVEMENT_PROCESS', false)
          commit('DELETE_SHIP_MOVEMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_SHIP_MOVEMENT_PROCESS', false)
          if (error.response) {
            commit('DELETE_SHIP_MOVEMENT_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_SHIP_MOVEMENT_ERROR', error.request)
          } else {
            commit('DELETE_SHIP_MOVEMENT_ERROR', error.message)
          }
        })
    },
  },
}
