import Vue from 'vue'
import VueRouter from 'vue-router'
// import { canNavigate } from '@/libs/acl/routeProtection'
import { isActiveToken, logoutUser, getHomeRouteForLoggedInUser } from '@/auth/utils'

// Routes
import app from './admin/index'
import auth from './auth/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/login',
    },
    ...app,
    ...auth,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const publicPages = ['login', 'forgot-password', 'reset-password', 'confirm-account']
  const authRequired = !publicPages.includes(to.name)
  const active = isActiveToken()

  // Redirect if user already logged in
  if (to.meta.redirectIfLoggedIn && active) {
    next(getHomeRouteForLoggedInUser())
  }

  if (authRequired && !active) {
    logoutUser()
    next('login')
  } else {
    next()
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
