import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    newCargoProcess: null,
    newCargoSuccess: null,
    newCargoError: null,

    newCargoListProcess: null,
    newCargoListSuccess: null,
    newCargoListError: null,

    updateCargoProcess: null,
    updateCargoSuccess: null,
    updateCargoError: null,

    deleteCargoProcess: null,
    deleteCargoError: null,
    deleteCargoSuccess: null,
  },
  getters: {
    newCargoProcess: state => state.newCargoProcess,
    newCargoSuccess: state => state.newCargoSuccess,
    newCargoError: state => state.newCargoError,

    newCargoListProcess: state => state.newCargoListProcess,
    newCargoListSuccess: state => state.newCargoListSuccess,
    newCargoListError: state => state.newCargoListError,

    updateCargoProcess: state => state.updateCargoProcess,
    updateCargoSuccess: state => state.updateCargoSuccess,
    updateCargoError: state => state.updateCargoError,

    deleteCargoProcess: state => state.deleteCargoProcess,
    deleteCargoSuccess: state => state.deleteCargoSuccess,
    deleteCargoError: state => state.deleteCargoError,
  },
  mutations: {

    NEW_CARGO_PROCESS(state, payload) {
      state.newCargoProcess = payload
    },
    NEW_CARGO_SUCCESS(state, payload) {
      state.newCargoSuccess = payload
    },
    NEW_CARGO_ERROR(state, payload) {
      state.newCargoError = payload
    },

    CLEAR_NEW_CARGO(state) {
      state.newCargoError = null
      state.newCargoProcess = null
      state.newCargoSuccess = null
    },

    NEW_CARGO_LIST_PROCESS(state, payload) {
      state.newCargoListProcess = payload
    },
    NEW_CARGO_LIST_SUCCESS(state, payload) {
      state.newCargoListSuccess = payload
    },
    NEW_CARGO_LIST_ERROR(state, payload) {
      state.newCargoListError = payload
    },

    CLEAR_NEW_LIST_CARGO(state) {
      state.newCargoListError = null
      state.newCargoListProcess = null
      state.newCargoListSuccess = null
    },

    UPDATE_CARGO_PROCESS(state, payload) {
      state.updateCargoProcess = payload
    },
    UPDATE_CARGO_SUCCESS(state, payload) {
      state.updateCargoSuccess = payload
    },
    UPDATE_CARGO_ERROR(state, payload) {
      state.updateCargoError = payload
    },
    CLEAR_UPDATE_CARGO(state) {
      state.updateCargoError = null
      state.updateCargoProcess = null
      state.updateCargoSuccess = null
    },

    DELETE_CARGO_PROCESS(state, payload) {
      state.deleteCargoProcess = payload
    },
    DELETE_CARGO_SUCCESS(state, payload) {
      state.deleteCargoSuccess = payload
    },
    DELETE_CARGO_ERROR(state, payload) {
      state.deleteCargoError = payload
    },
    CLEAR_DELETE_CARGO(state) {
      state.deleteCargoSuccess = null
      state.deleteCargoProcess = null
      state.deleteCargoError = null
    },
  },
  actions: {
    newNatureCargo({ commit }, payload) {
      commit('CLEAR_NEW_CARGO')
      commit('NEW_CARGO_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
      })
      axios
        .post('/api/nature-cargo/add', data, {
          headers: {
            Authorization: `Bearer ${userToken.get()}`,
          },
        })
        .then(res => {
          commit('NEW_CARGO_PROCESS', false)
          commit('NEW_CARGO_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('NEW_CARGO_PROCESS', false)
          if (error.response) {
            commit('NEW_CARGO_ERROR', error.response.data)
          } else if (error.request) {
            commit('NEW_CARGO_ERROR', error.request)
          } else {
            commit('NEW_CARGO_ERROR', error.message)
          }
        })
    },
    newListNatureCargo({ commit }, payload) {
      commit('CLEAR_NEW_LIST_CARGO')
      commit('NEW_CARGO_LIST_PROCESS', true)
      const data = JSON.stringify({
        data_list: payload.dataList,
      })
      axios
        .post('​/api​/nature-cargo​/add-all', data)
        .then(res => {
          commit('NEW_CARGO_LIST_PROCESS', false)
          commit('NEW_CARGO_LIST_SUCCESS', res.data)
          // this.$router.push({ path: "/account/login" });
        })
        .catch(error => {
          commit('NEW_CARGO_LIST_PROCESS', false)
          if (error.response) {
            commit('NEW_CARGO_LIST_ERROR', error.response.data)
          } else if (error.request) {
            commit('NEW_CARGO_LIST_ERROR', error.request)
          } else {
            commit('NEW_CARGO_LIST_ERROR', error.message)
          }
        })
    },
    updateCargo({ commit }, payload) {
      commit('CLEAR_UPDATE_CARGO')
      commit('UPDATE_CARGO_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
      })
      axios({
        method: 'put',
        url: `/api/nature-cargo/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_CARGO_PROCESS', false)
          commit('UPDATE_CARGO_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_CARGO_PROCESS', false)
          if (error.response) {
            commit('UPDATE_CARGO_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_CARGO_ERROR', error.request)
          } else {
            commit('UPDATE_CARGO_ERROR', error.message)
          }
        })
    },
    deleteCargo({ commit }, payload) {
      commit('CLEAR_DELETE_CARGO')
      commit('DELETE_CARGO_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/nature-cargo/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_CARGO_PROCESS', false)
          commit('DELETE_CARGO_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_CARGO_PROCESS', false)
          if (error.response) {
            commit('DELETE_CARGO_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_CARGO_ERROR', error.request)
          } else {
            commit('DELETE_CARGO_ERROR', error.message)
          }
        })
    },
  },
}
