/* eslint-disable no-unused-vars */
import axios from '@/libs/axios'
import userToken from '@/localStorage/token'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    tenderProcess: null,
    tenderSuccess: null,
    tenderError: null,

    updateTenderProcess: null,
    updateTenderSuccess: null,
    updateTenderError: null,

    deleteTenderProcess: null,
    deleteTenderError: null,
    deleteTenderSuccess: null,
  },
  getters: {
    tenderProcess: state => state.tenderProcess,
    tenderSuccess: state => state.tenderSuccess,
    tenderError: state => state.tenderError,

    updateTenderProcess: state => state.updateTenderProcess,
    updateTenderSuccess: state => state.updateTenderSuccess,
    updateTenderError: state => state.updateTenderError,

    deleteTenderProcess: state => state.deleteTenderProcess,
    deleteTenderSuccess: state => state.deleteTenderSuccess,
    deleteTenderError: state => state.deleteTenderError,
  },
  mutations: {

    TENDER_PROCESS(state, payload) {
      state.tenderProcess = payload
    },
    TENDER_SUCCESS(state, payload) {
      state.tenderSuccess = payload
    },
    TENDER_ERROR(state, payload) {
      state.tenderError = payload
    },

    CLEAR_TENDER(state) {
      state.tenderError = null
      state.tenderProcess = null
      state.tenderSuccess = null
    },

    UPDATE_TENDER_PROCESS(state, payload) {
      state.updateTenderProcess = payload
    },
    UPDATE_TENDER_SUCCESS(state, payload) {
      state.updateTenderSuccess = payload
    },
    UPDATE_TENDER_ERROR(state, payload) {
      state.updateTenderError = payload
    },
    CLEAR_UPDATE_TENDER(state) {
      state.updateTenderError = null
      state.updateTenderProcess = null
      state.updateTenderSuccess = null
    },

    DELETE_TENDER_PROCESS(state, payload) {
      state.deleteTenderProcess = payload
    },
    DELETE_TENDER_SUCCESS(state, payload) {
      state.deleteTenderSuccess = payload
    },
    DELETE_TENDER_ERROR(state, payload) {
      state.deleteTenderError = payload
    },
    CLEAR_DELETE_TENDER(state) {
      state.deleteTenderSuccess = null
      state.deleteTenderProcess = null
      state.deleteTenderError = null
    },
  },
  actions: {
    addTender({ commit, state }, payload) {
      commit('CLEAR_TENDER')
      commit('TENDER_PROCESS', true)
      const formdata = new FormData()
      formdata.append('title', payload.title)
      formdata.append('content', payload.description)
      formdata.append('file', payload.file)
      axios({
        method: 'post',
        data: formdata,
        url: '/api/micro/tender/add',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('TENDER_PROCESS', false)
          commit('TENDER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('TENDER_PROCESS', false)
          if (error.response) {
            commit('TENDER_ERROR', error.response.data)
          } else if (error.request) {
            commit('TENDER_ERROR', error.request)
          } else {
            commit('TENDER_ERROR', error.message)
          }
        })
    },
    updateTender({ commit, state }, payload) {
      commit('CLEAR_UPDATE_TENDER')
      commit('UPDATE_TENDER_PROCESS', true)
      const data = JSON.stringify({
        title: payload.title,
        content: payload.description,
        file: payload.file,
      })
      axios({
        method: 'put',
        url: `/api/micro/tender/update/${payload.id}`,
        data,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('UPDATE_TENDER_PROCESS', false)
          commit('UPDATE_TENDER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_TENDER_PROCESS', false)
          if (error.response) {
            commit('UPDATE_TENDER_ERROR', error.response.data)
          } else if (error.request) {
            commit('UPDATE_TENDER_ERROR', error.request)
          } else {
            commit('UPDATE_TENDER_ERROR', error.message)
          }
        })
    },
    deleteTender({ commit, state }, payload) {
      commit('CLEAR_DELETE_TENDER')
      commit('DELETE_TENDER_PROCESS', true)
      axios({
        method: 'delete',
        url: `/api/micro/tender/delete/${payload.id}`,
        headers: {
          Authorization: `Bearer ${userToken.get()}`,
        },
      })
        .then(res => {
          commit('DELETE_TENDER_PROCESS', false)
          commit('DELETE_TENDER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_TENDER_PROCESS', false)
          if (error.response) {
            commit('DELETE_TENDER_ERROR', error.response.data)
          } else if (error.request) {
            commit('DELETE_TENDER_ERROR', error.request)
          } else {
            commit('DELETE_TENDER_ERROR', error.message)
          }
        })
    },
  },
}
